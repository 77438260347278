import noUiSlider from 'nouislider';
import Choices from 'choices.js';
import Global from './global';


export class PackagesArchive {
    constructor() {
        this.glob = new Global();
        this.packages = document.querySelector('.archive-packages');

        if (this.packages) {
            this.range = document.querySelector('.range_prices') ? document.querySelectorAll('.range_prices') : null;
            this.accord = document.querySelector('.accord') ? document.querySelectorAll('.accord') : null;

            !this.range || this.range.forEach(el => this.sRange(el));
            !this.accord || this.accord.forEach(el => this.toggleAccor(el));
            !this.packages.querySelector('.show_next__btn') || this.packages.querySelector('.show_next__btn ').addEventListener('click', () => this.ajaxPackages(this.packages.dataset.count));
            
            
            this.archivePackages(this.packages);
        }



    }

    archivePackages(el) {
        [el.querySelectorAll('select'), el.querySelectorAll('input[type="checkbox"]')].forEach(element => element.forEach(item => item.addEventListener('change', e => {
            // if (e.target.closest('.box__resorts__flex')){
            //     console.log(el.querySelector(`select[name="night"]`));
            //     let night = el.querySelector(`select[name="night"]`).closest('.choices');
            //     const choicesContainer = night;

            //     const selectElement = choicesContainer.querySelector('select[name="night"]');
                
            
            //     selectElement.innerHTML = "<option value='153'>1 Nights</option>";
            //     const singleChoiceUIContainer = choicesContainer.querySelector('.choices__list--single');
            //     singleChoiceUIContainer.innerHTML = ''; 

            //     const newSelectedItem = document.createElement('div');
            //     newSelectedItem.className = 'choices__item choices__item--selectable';
            //     newSelectedItem.setAttribute('data-item', '');
            //     newSelectedItem.setAttribute('data-id', '2'); 
            //     newSelectedItem.setAttribute('data-value', '153');
            //     newSelectedItem.setAttribute('data-custom-properties', 'null');
            //     newSelectedItem.setAttribute('aria-selected', 'true');
            //     newSelectedItem.textContent = '1 Nights';

            //     singleChoiceUIContainer.appendChild(newSelectedItem);

            //     const allOptionsUI = choicesContainer.querySelectorAll('.choices__item--choice');
            //     allOptionsUI.forEach(option => {
            //         const isMatch = option.getAttribute('data-value') === '153';
            //         option.classList.toggle('is-selected', isMatch);
            //         option.classList.toggle('is-highlighted', isMatch);
            //         option.setAttribute('aria-selected', isMatch.toString());
            //     });
            // }else{
               
            // }
            this.ajaxPackages();

        })));
    }

    toggleAccor(el) {
        el.querySelector('.accord__top').addEventListener('click', e => {
            e.target.classList.toggle('active');
            if (e.target.classList.contains('active')) e.target.querySelector('h4 span').innerText = 'Hide';
            else e.target.querySelector('h4 span').innerText = 'Show';
            this.glob.slideToggle(el.querySelector('.accord__down'), 500);
        });
    }
    sRange(el) {
        noUiSlider.create(el, {
            start: [0, 1000],
            connect: true,
            step: 1,
            range: {
                'min': 0,
                'max': 1000
            }
        });
        ['slide', 'change'].forEach(item => el.noUiSlider.on(item, () => {
            let min_price = parseInt(el.querySelector('.noUi-handle').ariaValueNow);
            let max_price = parseInt(el.querySelector('.noUi-handle').ariaValueMax);
            el.closest('.box__prices').querySelector('.price').innerText = `£${min_price} - £${max_price}`;
            
        }));
        el.noUiSlider.on('end', () => {
            this.ajaxPackages();
        })
    }

    ajaxPackages(count = 0) {
        let element = this.packages;
            //count = number;//element.dataset.count;
        const http = new XMLHttpRequest(),
            url = AJAXURL;
        let data = {
            action: 'ajax_packages',
            count: count,
        };
        data = { ...data, ...this.validSelect() };
        const params = this.glob.objectDecapit(data);

        http.open('POST', url, true);
        http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        http.onloadstart = () => {
            element.querySelector('.archive-curses__query__posts').classList.add('loader');
            element.querySelector('.show_next__btn').classList.add('active')
        };
        http.onreadystatechange = () => {
            if (http.readyState == 4 && http.status == 200) {
                let posts = JSON.parse(http.response);
                if (count == 0) {
                    element.querySelector('.archive-curses__query__posts').innerHTML = '';
                    element.dataset.count = 0;
                }
                if (posts.post) posts.post.forEach(item => element.querySelector('.archive-curses__query__posts').append(this.post_packages(item)));
                else element.querySelector('.archive-curses__query__posts').innerHTML = `<h2>${posts.no_found}</h2>`;

                let first = element.querySelector('.show_next h3 .first'),
                    last_number = element.querySelector('.show_next h3 .last'),
                    first_number = Number(first.innerText),
                    ps_count = posts.count === undefined ? 0 : posts.count;

                first.innerText = Number(count) === 0 ? ps_count : first_number + Number(ps_count);
                last_number.innerText = posts.all || 0;
                let procent = (Number(first.innerText) / posts.all) * 100 || 0;
                element.querySelector('.show_next__bars span').style.width = procent + '%';
                if (!posts.pagination) {
                    element.querySelector('.show_next__btn').classList.add('hidden');
                } else {
                    element.querySelector('.show_next__btn').classList.remove('hidden');
                }
                element.dataset.count = Number(element.dataset.count) + 9;
            }
        };
        http.onloadend = () => {
            element.querySelector('.archive-curses__query__posts').classList.remove('loader');
            element.querySelector('.show_next__btn').classList.remove('active')
        };
        http.send(params);
    }


    validSelect() {
        let obc = {};
        this.packages.querySelectorAll('.box').forEach(el => {
            if(el.querySelector('select')) obc[el.querySelector('select').name] = el.querySelector('select').value || false;
        });

        let minmax = this.packages.querySelector('.noUi-handle.noUi-handle-lower');
        let price = this.packages.querySelector('.box__prices');
        let resorts = this.packages.querySelector('.box__resorts');
        let cursetype = this.packages.querySelector('.box__radios');
        let facilities = this.packages.querySelector('.accord__down--acilities');
        let features = this.packages.querySelector('.accord__down--features');

        obc['min_price'] = parseInt(minmax.ariaValueNow);
        obc['max_price'] = parseInt(minmax.ariaValueMax);
        obc['season_only'] = price.querySelector('.box__prices__check input').checked;
        obc['resorts_check'] = resorts.querySelector('.box__resorts__flex input').checked;
        
        let arr_curse = new Array();
        let arr_facilities = new Array();
        let arr_features = new Array();
        cursetype.querySelectorAll('ul li').forEach(li => {
            if(li.querySelector('input').checked){
                arr_curse.push(li.querySelector('input').value);
            }
        });
        facilities.querySelectorAll('label').forEach(label => {
                if(label.querySelector('input').checked){
                    arr_facilities.push(label.querySelector('input').value);
                }
        })
        features.querySelectorAll('label').forEach(label => {
            if (label.querySelector('input').checked) {
                arr_features.push(label.querySelector('input').value);
            }
        })

        obc['curse_type'] = arr_curse.length ? arr_curse.join() : false;
        obc['facilities'] = arr_facilities.length ? arr_facilities.join() : false;
        obc['features'] = arr_features.length ? arr_features.join() : false;

        return obc;
       
    }

    post_packages(element) {
        let div = document.createElement('div'),
            prices = '',
            lists = [],
            promo = [];
        div.classList.add('show_packages__box');

        if (element.price.price != 0) {
            prices = `<span class="price">
                <p>From</p>
                <h4 class="price_regular ${element.price.price_promoto ? 'price_regular--despile' : ''}">£${element.price.price}</h4>
                ${element.price.price_promoto ? `<h4 class="price_promoto">£${element.price.price_promoto}</h4>` : ''}
            </span>`
        }
        if (element.lists) element.lists.forEach(item => lists.push(`<li><a href="${item.link}">${item.title}</a></li>`));
        if (element.promo) element.promo.forEach(item => promo.push(`<li>${item}</li>`));
        div.innerHTML = `
            <a class="image" href="${element.link}">
                <img  src="${element.image}" alt="${element.title_image}">
                ${prices}
            </a>
            ${element.category ? `<h4>${element.category.join(', ')}</h4>` : ''}
            <h3><a href="${element.link}">${element.title}</a></h3>
            <ul class="promoto">${promo.join('')}</ul>
            ${lists && lists.length != 0 ? `<ul>${lists.join('')}</ul>` : ''}
        `;
        return div; 
    }

    post(element) {
        let div = document.createElement('div');
        div.classList.add('box');
        div.innerHTML = `<div class="show_packages__box"><a class="image" href="${element.link}"><img src="${element.image}" alt="${element.title_image}"></a><h3><a href="${element.link}">${element.title}</a></h3>${element.categories}</div>`;
        return div;
    }

}
 
export default PackagesArchive;